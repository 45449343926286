import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AdminState {
  isAuthenticated: boolean;
  user: any | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
}

const useAdminStore = create<AdminState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,

      login: async (email: string, password: string) => {
        // For demo, use hardcoded credentials
        if (email === 'admin@sigmaverse.in' && password === 'admin123') {
          set({ 
            isAuthenticated: true,
            user: {
              email,
              role: 'admin',
              name: 'Admin User'
            }
          });
          return true;
        }
        return false;
      },

      logout: () => {
        set({ 
          isAuthenticated: false,
          user: null
        });
      },
    }),
    {
      name: 'admin-storage',
      getStorage: () => localStorage,
    }
  )
);

export default useAdminStore;