export const MOCK_TRANSIT = [
  {
    id: 'metro-1',
    title: 'Premium Metro Station Branding',
    type: 'metro_station',
    location: {
      city: 'Delhi',
      area: 'Rajiv Chowk Metro Station',
      routes: ['Blue Line', 'Yellow Line']
    },
    specifications: {
      footfall: '500,000+ daily',
      format: 'Station Domination',
      duration: 'Minimum 3 months',
      coverage: 'Entire Station'
    },
    pricing: {
      basePrice: 250000,
      unit: 'per_month',
      minimumDuration: 3
    },
    features: [
      'High Footfall',
      'Premium Location',
      'Multiple Formats',
      'Long Dwell Time'
    ],
    metrics: {
      dailyFootfall: '500,000+',
      peakHours: '9AM-11AM, 5PM-8PM',
      targetAudience: 'Urban Commuters'
    },
    images: [
      'https://images.unsplash.com/photo-1565035010268-a3816f98589a',
      'https://images.unsplash.com/photo-1565035010268-a3816f98589b'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-04-01'
    }
  },
  {
    id: 'bus-1',
    title: 'City Bus Fleet Advertising',
    type: 'bus_fleet',
    location: {
      city: 'Mumbai',
      area: 'Pan City',
      routes: ['Premium Routes', 'Business Districts']
    },
    specifications: {
      fleetSize: '100 buses',
      format: 'Full Bus Wrap',
      duration: 'Minimum 6 months',
      coverage: 'Major Routes'
    },
    pricing: {
      basePrice: 180000,
      unit: 'per_month',
      minimumDuration: 6
    },
    features: [
      'Wide Coverage',
      'Moving Advertisement',
      'High Visibility',
      'Route Selection'
    ],
    metrics: {
      dailyPassengers: '100,000+',
      routeCoverage: '25 Major Routes',
      targetAudience: 'Mass Market'
    },
    images: [
      'https://images.unsplash.com/photo-1570125909232-eb263c188f7e',
      'https://images.unsplash.com/photo-1570125909232-eb263c188f7f'
    ],
    availability: {
      status: 'available',
      nextAvailable: 'Immediate'
    }
  },
  {
    id: 'train-1',
    title: 'Local Train Branding Package',
    type: 'train',
    location: {
      city: 'Mumbai',
      area: 'Western Line',
      routes: ['Churchgate to Virar']
    },
    specifications: {
      trainSets: '5 trains',
      format: 'Interior & Exterior Branding',
      duration: 'Minimum 6 months',
      coverage: 'Western Line'
    },
    pricing: {
      basePrice: 300000,
      unit: 'per_month',
      minimumDuration: 6
    },
    features: [
      'Mass Reach',
      'Commuter Audience',
      'Long Duration',
      'Multiple Formats'
    ],
    metrics: {
      dailyPassengers: '750,000+',
      routeCoverage: '37 Stations',
      targetAudience: 'Daily Commuters'
    },
    images: [
      'https://images.unsplash.com/photo-1581262177000-8139a463e531',
      'https://images.unsplash.com/photo-1581262177000-8139a463e532'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-04-15'
    }
  }
];

export const TRANSIT_CATEGORIES = [
  {
    id: 'metro_station',
    name: 'Metro Stations',
    description: 'Premium advertising in metro stations',
    icon: 'Train'
  },
  {
    id: 'bus_fleet',
    name: 'Bus Fleets',
    description: 'City bus and interstate bus advertising',
    icon: 'Bus'
  },
  {
    id: 'train',
    name: 'Train Advertising',
    description: 'Local and intercity train branding',
    icon: 'Train'
  },
  {
    id: 'airport_transit',
    name: 'Airport Transit',
    description: 'Airport shuttle and transport advertising',
    icon: 'Plane'
  }
];